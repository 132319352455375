import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Lang from '../../../lang';

function Leftbar() {

  const location = useLocation();
  const [openedMenu, setOpenedMenu] = React.useState('');

  return (
    <div className="Leftbar shadow">
      <div className="navbar-brand-box">
        <Link className="d-block h-100 text-center" to="/">
          {/* <img src={require('../../../assets/boathouse.png')} className="w-100 mt-2" alt="" /> */}
          <h2 className="mb-0 mt-3">{Lang.app_name}</h2>
        </Link>
      </div>
      <div className="sidebar-menu-scroll">
        <ul className="nav-list">
          <li className={(location.pathname.includes('/real-time-orders') || location.pathname === '/') ? "nav-item active" : "nav-item"}>
            <Link to={'/real-time-orders'} onClick={() => {setOpenedMenu('');}}>1. {Lang.real_time_order_acceptance}</Link>
          </li>
          <li className={location.pathname.includes('/number-distribution') ? "nav-item active" : "nav-item"}>
            <Link to={'/number-distribution'} onClick={() => {setOpenedMenu('');}}>2. {Lang.number_distribution}</Link>
          </li>
          <li className={location.pathname.includes('/total-order') ? "nav-item active" : "nav-item"}>
            <Link to={'/total-order'} onClick={() => {setOpenedMenu('');}}>3. {Lang.total_order}</Link>
          </li>
          <li className={location.pathname.includes('/order-information') ? "nav-item active" : "nav-item"}>
            <Link to={'/order-information'} onClick={() => {setOpenedMenu('');}}>4. {Lang.order_information}</Link>
          </li>
          <li className={location.pathname.includes('/winning-lottery-number') ? "nav-item active" : "nav-item"}>
            <Link to={'/winning-lottery-number'} onClick={() => {setOpenedMenu('');}}>5. {Lang.winning_lottery_number}</Link>
          </li>
          <li className={location.pathname.includes('/number-money-limit') ? "nav-item active" : "nav-item"}>
            <Link to={'/number-money-limit'} onClick={() => {setOpenedMenu('');}}>6. {Lang.number_limit}</Link>
          </li>
          <li className={location.pathname.includes('/regional-time-limit') ? "nav-item active" : "nav-item"}>
            <Link to={'/regional-time-limit'} onClick={() => {setOpenedMenu('');}}>7. {Lang.regional_time_limit}</Link>
          </li>
          <li className={location.pathname.includes('/online-status') ? "nav-item active" : "nav-item"}>
            <Link to={'/online-status'} onClick={() => {setOpenedMenu('');}}>8. {Lang.online_status}</Link>
          </li>

          <li className={location.pathname.includes('/machines') ? "nav-item active" : "nav-item"}>
            <Link to={'/machines'} onClick={() => {setOpenedMenu('');}}>9. {Lang.set_account_password}</Link>
          </li>
          <li className={location.pathname.includes('/settings') ? "nav-item active" : "nav-item"}>
            <Link to={'/settings'} onClick={() => {setOpenedMenu('');}}>10. {Lang.platform_settings}</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Leftbar;
