import React, { useRef } from 'react';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import _ from "lodash";
import ReactToPrint from 'react-to-print';
import PageHeader from '../components/PageHeader';
import {RegionFactory, Utils} from '../../factory';
import Lang from '../../lang';

function WinningLotteryNumber() {
  // const [date, setDate] = React.useState(moment().tz('America/Caracas').format('YYYY-MM-DD'));
  const [date, setDate] = React.useState(moment().tz('America/Toronto').format('YYYY-MM-DD'));
  const [regions, setRegions] = React.useState([]);
  const [todayWinningNumbers, setTodayWinningNumbers] = React.useState([]);
  const [searchDate, setSearchDate] = React.useState({
    // start: moment().tz('America/Caracas').subtract(7, 'd').format('YYYY-MM-DD'),
    start: moment().tz('America/Toronto').subtract(7, 'd').format('YYYY-MM-DD'),
    // end: moment().tz('America/Caracas').format('YYYY-MM-DD')
    end: moment().tz('America/Toronto').format('YYYY-MM-DD')
  });
  const [paid, setPaid] = React.useState([]);
  const [showStatus, setShowStatus] = React.useState(true);

  const componentRef = useRef();
  const componentRefs = useRef();

  React.useEffect(function() {
    getWinningNumbersByDate(date);
    getWinningLotteryNumbers(searchDate);
  }, []);

  const getWinningLotteryNumbers = (searchDate) => {
    Utils.showSpinner();
    RegionFactory.getWinningLotteryNumbers(searchDate)
      .then(res => {
        if (res.status === 200) {
          setRegions(res.data.regions);
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }

  const handleChangeInputDate = (target) => {
    setDate(target.value);
    getWinningNumbersByDate(target.value);
  }

  const getWinningNumbersByDate = (_date) => {
    RegionFactory.getWinningNumbersByDate({date: _date})
      .then(res => {
        if (res.status === 200) {
          setTodayWinningNumbers(res.data.todayWinningNumbers);
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }

  const handleRankNumChange = (target, index) => {
    const todayWinningNumArr = [...todayWinningNumbers];
    const todayWinningNumItem = {...todayWinningNumArr[index]};
    todayWinningNumItem[target.name] = target.value;
    todayWinningNumArr[index] = todayWinningNumItem;
    setTodayWinningNumbers(todayWinningNumArr);
  }

  const saveWinningNumbers = () => {
    const data = {
      winning_numbers: todayWinningNumbers
    }
    if(!checkWinningNumbersFormat()) {
      Utils.showToast('warning', 3000, Lang.Invalid_Number_Format);
      return;
    }
    Utils.showSpinner();
    RegionFactory.saveWinningNumbers(data)
      .then(res => {
        if(res.data && res.data.length > 0){
            setPaid(res.data);
            setShowStatus(false);
        }else{
            setShowStatus(true);
        }
        getWinningLotteryNumbers();
      })
      .catch(err => {
        console.log('err: ', err);
      })
  }

  const checkWinningNumbersFormat = () => {
    var result = true;
    for(var i in todayWinningNumbers) {
      if ((todayWinningNumbers[i].rank1_number !== '' && todayWinningNumbers[i].rank1_number.length !== 4)
        || (todayWinningNumbers[i].rank2_number !== '' && todayWinningNumbers[i].rank2_number.length !== 4)
        || (todayWinningNumbers[i].rank3_number !== '' && todayWinningNumbers[i].rank3_number.length !== 4)) {
        result = false;
        break;
      }
    }
    return result;
  }

  const handleChangeSearchDate = (target) => {
    var search = {...searchDate};
    search[target.name] = target.value;
    setSearchDate(search);
  }

  const searchWinningLotterNumbersByDate = () => {
    console.log('search: ', searchDate);
    getWinningLotteryNumbers(searchDate);
  }

  return (
    <div className="WinningLotteryNumber">
      <PageHeader title={'5. '+Lang.winning_lottery_number} />
      {/*  */}
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="form-group d-flex mx-auto">
              <label className="py-2 pr-5" style={{minWidth: 100}}>{Lang.date}</label>
              <input type="date" className="form-control"
                // max={moment().tz('America/Caracas').format('YYYY-MM-DD')}
                max={moment().tz('America/Toronto').format('YYYY-MM-DD')}
                // min={moment().tz('America/Caracas').subtract(1, 'd').format('YYYY-MM-DD')}
                min={moment().tz('America/Toronto').subtract(1, 'd').format('YYYY-MM-DD')}
                value={date}
                onChange={($event) => handleChangeInputDate($event.target)}
              />
            </div>
          </div>
          <div className="row mx-auto">
            <div className="" style={{width: 30}}>
              <div className="form-group mx-auto mb-0">
                <div className="form-control my-0 border-0"></div>
                <div className="form-control m-1 border-0">1</div>
                <div className="form-control m-1 border-0">2</div>
                <div className="form-control m-1 border-0">3</div>
              </div>
            </div>
            <div className="winning-number-input-container d-flex" style={{width: 'calc(100% - 120px)'}}>
              {todayWinningNumbers && todayWinningNumbers.map((item, index) =>
                <div className="form-group mx-auto mb-0" key={index}>
                  <div className="form-control my-0 border-0 text-center">{item.region_name}</div>
                  <input type="text" className="form-control my-1" name="rank1_number" minLength="4" maxLength="4" value={item.rank1_number} onChange={($event) => handleRankNumChange($event.target, index)} />
                  <input type="text" className="form-control my-1" name="rank2_number" minLength="4" maxLength="4" value={item.rank2_number} onChange={($event) => handleRankNumChange($event.target, index)} />
                  <input type="text" className="form-control my-1" name="rank3_number" minLength="4" maxLength="4" value={item.rank3_number} onChange={($event) => handleRankNumChange($event.target, index)} />
                </div>
              )}
            </div>
            <div className="ml-auto mt-auto" style={{width: 100}}>
              <button type="button" className="btn btn-primary"
                onClick={() => saveWinningNumbers()}
              >{Lang.determine}</button>
            </div>
          </div>
        </div>

        <div className="card-body p-0" hidden={showStatus}>
            <div className="row mx-auto" ref={componentRefs}>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th colSpan="9">
                            错误中奖号码已兑奖订单
                            <ReactToPrint
                                trigger={() => <button type="button" className="btn btn-primary ml-3">{Lang.print}</button>}
                                content={() => componentRefs.current}
                            />
                        </th>
                    </tr>
                    <tr>
                        <th>{Lang.machine_number}</th>
                        <th>{Lang.number}</th>
                        <th>{Lang.pool}</th>
                        <th>{Lang.amount}</th>
                        <th>{Lang.serial_number}</th>
                        <th>{Lang.order_time}</th>
                        <th>{Lang.region}</th>
                        <th>{Lang.winning_amount}</th>
                        <th>{Lang.winning_payment_time}</th>
                    </tr>
                    </thead>

        {paid && paid.map((item) =>
                      <tr>
                          <td>{item.machine_number}</td>
                          <td>{item.bet_number}</td>
                          <td>{item.is_pool ? 'pool' : ''}</td>
                          <td>{item.bet_money.toFixed(2)}</td>
                          <td>{item.serial_number}</td>
                          {/*<td>{moment(item.created_at).tz('America/Caracas').format('YYYY-MM-DD HH:mm:ss')}</td>*/}
                          <td>{moment(item.created_at).tz('America/Toronto').format('YYYY-MM-DD HH:mm:ss')}</td>
                          <td>{item.belong_to}</td>
                          <td>{item.prizes.toFixed(2)}</td>
                          <td>{item.paid_at}</td>
                      </tr>
        )}
                </table>
            </div>
        </div>


        <div className="card-body p-0">
          <div className="row">
            <div className="d-flex mx-auto mt-3">
              <label className="p-2">{Lang.time}: </label>
              <div className="form-group">
                <input type="date" className="form-control"
                  name="start"
                  max={searchDate.end}
                  value={searchDate.start}
                  onChange={($event) => handleChangeSearchDate($event.target)} />
              </div>
              <label className="p-2"> - </label>
              <div className="form-group">
                <input type="date" className="form-control"
                  name="end"
                  min={searchDate.start}
                  // max={moment().tz('America/Caracas').format('YYYY-MM-DD')}
                  max={moment().tz('America/Toronto').format('YYYY-MM-DD')}
                  value={searchDate.end}
                  onChange={($event) => handleChangeSearchDate($event.target)} />
              </div>
              <div className="form-group">
                <button type="button" className="btn btn-primary ml-3"
                  onClick={() => searchWinningLotterNumbersByDate()}
                >{Lang.search}</button>
              </div>
              <div className="form-group">
                <ReactToPrint
                  trigger={() => <button type="button" className="btn btn-primary ml-3">{Lang.print}</button>}
                  content={() => componentRef.current}
                />
              </div>
            </div>
          </div>
          <div className="row mx-auto" ref={componentRef}>
            <div className="" style={{width: 100}}>
              {regions && regions.map((item, index) =>
                {if (index === 0)
                  return <div className="w-100 mx-auto mb-0" key={index}>
                          <div className="text-center tb-cell">{Lang.time}</div>
                          {item.winning_lottery_numbers && item.winning_lottery_numbers.map((iitem, iindex) =>
                            <div className="row mx-0" key={iindex}>
                              <div className="" style={{width: 50, border: 'solid 1px #ddd'}}>
                                <div className="text-center tb-cell" style={{border: 'solid 1px #dddddd00'}}><Moment format="YYYY">{iitem.date}</Moment></div>
                                <div className="text-center tb-cell" style={{border: 'solid 1px #dddddd00'}}><Moment format="MM">{iitem.date}</Moment></div>
                                <div className="text-center tb-cell" style={{border: 'solid 1px #dddddd00'}}><Moment format="D">{iitem.date}</Moment></div>
                              </div>
                              <div className="" style={{width: 50}}>
                                <div className="w-100" style={{border: 'solid 1px #ddd'}}>
                                  <div className="text-center tb-cell" style={{border: 'solid 1px #ddd'}}>1</div>
                                  <div className="text-center tb-cell" style={{border: 'solid 1px #ddd'}}>2</div>
                                  <div className="text-center tb-cell" style={{border: 'solid 1px #ddd'}}>3</div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                }
              )}
            </div>
            <div className="winning-number-input-container d-flex" style={{width: 'calc(100% - 100px)'}}>
              {regions && regions.map((item, index) =>
                <div className="w-100 mx-auto mb-0" key={index}>
                  <div className="text-center tb-cell">{item.region_name}</div>
                  {item.winning_lottery_numbers && item.winning_lottery_numbers.map((iitem, iindex) =>
                    <div className="w-100" key={iindex} style={{border: 'solid 1px #ddd'}}>
                      <div className="text-center tb-cell" style={{border: 'solid 1px #ddd'}}>{iitem.rank1_number}</div>
                      <div className="text-center tb-cell" style={{border: 'solid 1px #ddd'}}>{iitem.rank2_number}</div>
                      <div className="text-center tb-cell" style={{border: 'solid 1px #ddd'}}>{iitem.rank3_number}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2" fillRule="evenodd"></path></svg>
          </button>
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17.707 9.293l-5-5a.999.999 0 1 0-1.414 1.414L14.586 9H3a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414" fillRule="evenodd"></path></svg>
          </button>
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default WinningLotteryNumber;
