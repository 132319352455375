import React, { useRef } from 'react';
// import Moment from 'react-moment';
import moment from 'moment-timezone';
import _ from "lodash";
import ReactToPrint from 'react-to-print';
import PageHeader from '../components/PageHeader';
import TableOrderBy from '../components/TableOrderBy';
import {OrderFactory, Utils} from '../../factory';
import Lang from '../../lang';

function NumberDistribution() {
  const [betStatics, setBetStatics] = React.useState({
    totalMachines: 0,
    totalBetNumbers: 0,
    totalPoolBetNumbers: 0,
    totalBetMoney: 0
  });
  const [betNumbers, setBetNumbers] = React.useState([]);
  const [searchParam, setSearchParam] = React.useState({
    machine_number: '',
    bet_number: '',
    all_machines: true,
    // start: moment().tz('America/Caracas').subtract(0, 'd').format('YYYY-MM-DD'),
    start: moment().tz('America/Toronto').subtract(0, 'd').format('YYYY-MM-DD'),
    // end: moment().tz('America/Caracas').format('YYYY-MM-DD'),
    end: moment().tz('America/Toronto').format('YYYY-MM-DD'),
    regions: '',
    order_column: 'sub_total_bet_money',
    order_by: 'DESC',
  });

  const componentRef = useRef();

  React.useEffect(function() {
    getNumberDistributions(searchParam);
  }, []);

  const getNumberDistributions = (searchParam) => {
    Utils.showSpinner();
    OrderFactory.getNumberDistributions(searchParam)
      .then(res => {
        if (res.status === 200) {
          setBetNumbers(res.data.betNumbers);
          setBetStatics(res.data.betStatics);
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }


  const handleSearchInput = (target) => {
    var search = {...searchParam};
    search[target.name] = target.type === 'checkbox' ? target.checked : target.value;
    switch(target.name) {
      case 'all_machines' :
        search['machine_number'] = '';
        search['bet_number'] = '';
        break;
      case 'machine_number' :
        search['all_machines'] = false;
        search['bet_number'] = '';
        break;
      case 'bet_number' :
        search['all_machines'] = false;
        search['machine_number'] = '';
        break;
      default :
        search['all_machines'] = true;
        search['bet_number'] = '';
        search['machine_number'] = '';
    }
    setSearchParam(search);
  }

  const chooseRegionTab = (region) => {
    var search = {...searchParam};
    if(region === '') {
      search['regions'] = '';
    } else {
      if(search.regions.includes(region)) {
        search['regions'] = search.regions.split(region + ',').join('');
      } else {
        search['regions'] = search.regions + region + ',';
      }
    }
    setSearchParam(search);
    getNumberDistributions(search);
  }

  const handleOrderBy = (column, orderBy) => {
    var search = {...searchParam};
    search["order_column"] = column;
    search["order_by"] = orderBy;
    setSearchParam(search);
    // getNumberDistributions(search);
  }

  function desc(a, b, orderBy) {
    if (_.get(b, orderBy) < _.get(a, orderBy)) {
      return -1;
    }
    if (_.get(b, orderBy) > _.get(a, orderBy)) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "DESC"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  return (
    <div className="NumberDistribution">
      <PageHeader title={'2. '+Lang.number_distribution} />
      {/*  */}
      <div className="card">
        <div className="card-header">
          <div className="row pt-1">
            <div className="col-3">
              <div className="form-group d-flex m-0">
                <label className="py-2 mr-2"> {Lang.all_machines}: </label>
                <input type="checkbox" className="m-2" style={{width: 20, height: 20}}
                  name="all_machines"
                  checked={searchParam.all_machines}
                  onChange={($event) => handleSearchInput($event.target)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group d-flex m-0">
                {/*<label className="py-2 mr-2"> {Lang.machine_number}: </label>*/}
                <input type="text" className="form-control" style={{width: 180}}
                  placeholder={Lang.machine_number}
                  name="machine_number"
                  value={searchParam.machine_number}
                  onChange={($event) => handleSearchInput($event.target)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group d-flex m-0">
                {/*<label className="py-2 mr-2"> {Lang.number}: </label>*/}
                <input type="text" className="form-control" style={{width: 180}}
                  placeholder={Lang.number}
                  name="bet_number"
                  value={searchParam.bet_number}
                  onChange={($event) => handleSearchInput($event.target)}
                />
              </div>
            </div>
          </div>
          <div className="row mx-0 pt-1">
            <label className="py-2 mr-2">{Lang.time}: </label>
            <div className="form-group">
              <input type="date" className="form-control"
                name="start"
                max={searchParam.end}
                value={searchParam.start}
                onChange={($event) => handleSearchInput($event.target)} />
            </div>
            <label className="p-2"> - </label>
            <div className="form-group">
              <input type="date" className="form-control"
                name="end"
                min={searchParam.start}
                // max={moment().tz('America/Caracas').format('YYYY-MM-DD')}
                max={moment().tz('America/Toronto').format('YYYY-MM-DD')}
                value={searchParam.end}
                onChange={($event) => handleSearchInput($event.target)} />
            </div>
            <div className="form-group">
              <button type="button" className="btn btn-primary ml-3"
                onClick={() => getNumberDistributions(searchParam)}
              >{Lang.search}</button>
            </div>
            <div className="form-group">
              <ReactToPrint
                trigger={() => <button type="button" className="btn btn-primary ml-3">{Lang.print}</button>}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="row mx-0 p-1">
            <button className={searchParam.regions === '' ? "btn btn-primary m-2" : "btn btn-outline-primary m-2"}
              onClick={() => chooseRegionTab('')}
            >{Lang.all}</button>
            <button className={searchParam.regions.includes('K') ? "btn btn-primary m-2" : "btn btn-outline-primary m-2"}
              onClick={() => chooseRegionTab('K')}
            >Korsou</button>
            <button className={searchParam.regions.includes('F') ? "btn btn-primary m-2" : "btn btn-outline-primary m-2"}
              onClick={() => chooseRegionTab('F')}
            >Flamingo</button>
            <button className={searchParam.regions.includes('D') ? "btn btn-primary m-2" : "btn btn-outline-primary m-2"}
              onClick={() => chooseRegionTab('D')}
            >Diario</button>
            <button className={searchParam.regions.includes('S') ? "btn btn-primary m-2" : "btn btn-outline-primary m-2"}
              onClick={() => chooseRegionTab('S')}
            >ST</button>
          </div>
          <div className="row mx-auto" ref={componentRef}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  {/* {searchParam.machine_number === '' ? null : <th>{Lang.machine_number}</th>} */}
                  <th>{Lang.number}<TableOrderBy searchParam={searchParam} column="bet_number" handleOrderBy={handleOrderBy} /></th>
                  <th>{Lang.pool}<TableOrderBy searchParam={searchParam} column="is_pool" handleOrderBy={handleOrderBy} /></th>
                  <th>{Lang.amount}<TableOrderBy searchParam={searchParam} column="sub_total_bet_money" handleOrderBy={handleOrderBy} /></th>
                </tr>
              </thead>
              <tbody>
                {betNumbers && stableSort(betNumbers, getSorting(searchParam.order_by, searchParam.order_column)).map((item, index) =>
                  <tr key={index}>
                    {/* {searchParam.machine_number === '' ? null : <td>{searchParam.machine_number}</td>} */}
                    <td>{item.bet_number}</td>
                    <td>{item.is_pool ? 'pool' : ''}</td>
                    <td>{item.sub_total_bet_money.toFixed(2)}</td>
                  </tr>
                )}
              </tbody>
              {betNumbers && betNumbers.length > 0 &&
                <tfoot>
                  <tr>
                    {/* {searchParam.machine_number === '' ? null : <th>{betStatics.totalMachines}</th>} */}
                    <th>{betStatics.totalBetNumbers}</th>
                    <th>{betStatics.totalPoolBetNumbers}</th>
                    <th>{betStatics.totalBetMoney.toFixed(2)}</th>
                  </tr>
                </tfoot>
              }
            </table>
          </div>
        </div>
        <div className="card-footer">
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2" fillRule="evenodd"></path></svg>
          </button>
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17.707 9.293l-5-5a.999.999 0 1 0-1.414 1.414L14.586 9H3a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414" fillRule="evenodd"></path></svg>
          </button>
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default NumberDistribution;
