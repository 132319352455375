import env from '../env';
import { GET, POST } from './Utils';

const registerMachine = (data) => {
    const path = `${env.BASE_API}/admin/machine/register`;
    return POST(path, data);
}

const getMachines = () => {
    const path = `${env.BASE_API}/admin/machine/getMachines`;
    return GET(path);
}

const deleteMachine = (data) => {
    const path = `${env.BASE_API}/admin/machine/deleteMachine`;
    return POST(path, data);
}

const getOnlineStatus = () => {
    const path = `${env.BASE_API}/admin/machine/getOnlineStatus`;
    return GET(path);
}

const getCommissions = () => {
    const path = `${env.BASE_API}/admin/machine/getCommissions`;
    return GET(path);
}

const saveCommissionsChange = (data) => {
    const path = `${env.BASE_API}/admin/machine/saveCommissionsChange`;
    return POST(path, data);
}

const updateMachine = (data) => {
    const path = `${env.BASE_API}/admin/machine/updateMachine`;
    return POST(path, data);
}

const MachineFactory = {
    registerMachine,
    getMachines,
    deleteMachine,
    getOnlineStatus,
    getCommissions,
    saveCommissionsChange,
    updateMachine
}

export default MachineFactory;
