import env from '../env';
import { GET, POST } from './Utils';

const getRegions = () => {
    const path = `${env.BASE_API}/admin/region/getRegions`;
    return GET(path);
}

const saveRegionTimeLimit = (data) => {
    const path = `${env.BASE_API}/admin/region/saveRegionTimeLimit`;
    return POST(path, data);
}

const addSpecialNumber = (data) => {
    const path = `${env.BASE_API}/admin/region/addSpecialNumber`;
    return POST(path, data);
}

const deleteSpecialNumber = (data) => {
    const path = `${env.BASE_API}/admin/region/deleteSpecialNumber`;
    return POST(path, data);
}

const getWinningLotteryNumbers = (data) => {
    const path = `${env.BASE_API}/admin/region/getWinningLotteryNumbers`;
    return POST(path, data);
}

const getWinningNumbersByDate = (data) => {
    const path = `${env.BASE_API}/admin/region/getWinningNumbersByDate`;
    return POST(path, data);
}

const saveWinningNumbers = (data) => {
    const path = `${env.BASE_API}/admin/region/saveWinningNumbers`;
    return POST(path, data);
}

const RegionFactory = {
    getRegions,
    saveRegionTimeLimit,
    addSpecialNumber,
    deleteSpecialNumber,
    getWinningLotteryNumbers,
    getWinningNumbersByDate,
    saveWinningNumbers
}

export default RegionFactory;