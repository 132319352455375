import env from '../env';
import { POST } from './Utils';

const getRealTimeOrders = (data) => {
    const path = `${env.BASE_API}/admin/order/getRealTimeOrders`; 
    return POST(path, data);
}

const getNumberDistributions = (data) => {
    const path = `${env.BASE_API}/admin/order/getNumberDistributions`;
    return POST(path, data);
}

const getTotalOrders = (data) => {
    const path = `${env.BASE_API}/admin/order/getTotalOrders`;
    return POST(path, data);
}

const getWinningHistory = (data) => {
    const path = `${env.BASE_API}/admin/order/getWinningHistory`;
    return POST(path, data);
}

const getOrderInfomations = (data) => {
    const path = `${env.BASE_API}/admin/order/getOrderInfomations`;
    return POST(path, data);
}

const getWinningHistoryByBetNumberId = (data) => {
    const path = `${env.BASE_API}/admin/order/getWinningHistoryByBetNumberId`;
    return POST(path, data);
}

const removeOrderData = (data) => {
    const path = `${env.BASE_API}/admin/order/removeOrderData`;
    return POST(path, data);
}

const OrderFactory = {
    getRealTimeOrders,
    getNumberDistributions,
    getTotalOrders,
    getWinningHistory,
    getOrderInfomations,
    getWinningHistoryByBetNumberId,
    removeOrderData
}

export default OrderFactory;