import React from 'react';
import PageHeader from '../components/PageHeader';
import {MachineFactory, Utils} from '../../factory';
import Lang from '../../lang';

function OnlineStatus() {
  const [machines, setMachines] = React.useState([]);
  const [onlineMachines, setOnlineMachines] = React.useState([]);
  const [offlineMachines, setOfflineMachines] = React.useState([]);

  React.useEffect(function() {
    const interval = setInterval(() => {
      getOnlineStatus();
    }, 5000);

    getOnlineStatus();

    return () => clearInterval(interval);
  }, []);

  const getOnlineStatus = () => {
    // Utils.showSpinner();
    MachineFactory.getOnlineStatus()
      .then(res => {
        if (res.status === 200) {
          setMachines(res.data.machines);
          setOnlineMachines(res.data.online);
          setOfflineMachines(res.data.offline);
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }

  return (
    <div className="OnlineStatus">
      <PageHeader title={'8. '+Lang.online_status} />
      {/*  */}
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-6 text-center">
              {Lang.online_machines}: ({onlineMachines.length})
            </div>
            <div className="col-6 text-center">
              {Lang.offline_machines}: ({offlineMachines.length})
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <table className="table table-striped table-hover m-0">
            <thead className="">
              <tr>
                <th>No</th>
                <th>{Lang.machine_number}</th>
                <th>{Lang.status}</th>
              </tr>
            </thead>
            <tbody>
              {
                machines && machines.map((item, index) => 
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.machine_number}</td>
                    <td>
                      <div className={item.is_online ? "bubble online" : "bubble offline"}></div>
                    </td>
                  </tr>
                )
              }
              
            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2" fillRule="evenodd"></path></svg>
          </button>
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17.707 9.293l-5-5a.999.999 0 1 0-1.414 1.414L14.586 9H3a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414" fillRule="evenodd"></path></svg>
          </button>
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default OnlineStatus;
