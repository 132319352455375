import React, { useRef } from 'react';
// import Moment from 'react-moment';
import moment from 'moment-timezone';
import _ from "lodash";
import ReactToPrint from 'react-to-print';
import PageHeader from '../components/PageHeader';
import {OrderFactory, Utils} from '../../factory';
import Lang from '../../lang';
import Modal from 'react-modal';
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : 0
  }
};

function OrderInformation() {
  const [winningHistories, setWinningHistories] = React.useState([]);
  const [totalWinningAmount, setTotalWinningAmount] = React.useState(0);
  const [orders, setOrders] = React.useState([]);
  const [searchParam, setSearchParam] = React.useState({
    machine_number: '',
    bet_number: '',
    serial_number: '',
    // start: moment().tz('America/Caracas').subtract(0, 'd').format('YYYY-MM-DD'),
    start: moment().tz('America/Toronto').subtract(0, 'd').format('YYYY-MM-DD'),
    // end: moment().tz('America/Caracas').format('YYYY-MM-DD'),
    end: moment().tz('America/Toronto').format('YYYY-MM-DD'),
    winning_order: false,
    unwinning_order: false,
    paid_order: false,
    unpaid_order: false,
    region_K: false,
    region_F: false,
    region_D: false,
    region_S: false,
  });

  const componentRef = useRef();

  React.useEffect(function() {
    getOrderInfomations(searchParam);
  }, []);

  const getOrderInfomations = (searchParam) => {
    Utils.showSpinner();
    OrderFactory.getOrderInfomations(searchParam)
      .then(res => {
        if (res.status === 200) {
          var orders = res.data.orders;
          setOrders(orders);
          var _totalWinningAmount = 0;
          for(var i = 0; i < orders.length; i++) {
            _totalWinningAmount += orders[i].total_winning_amount;
          }
          setTotalWinningAmount(_totalWinningAmount);
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }

  const calcTotalBetMoney = () => {
    var totalBetMoney = 0;
    if(orders) {
      orders.map((item) => {
        // totalBetMoney += _.sumBy(item.bet_numbers, 'bet_money') * item.belong_to.split('+').length;
        item.bet_numbers.map((bet)=> {
          totalBetMoney += bet.bet_money * bet.belong_to.split('+').length
        })
      });
    }
    return totalBetMoney;
  }

  const handleChangeSearchParam = (target) => {
    var search = {...searchParam};
    search[target.name] = target.type === 'checkbox' ? target.checked : target.value;
    setSearchParam(search);
    if(target.type === 'checkbox') {
      getOrderInfomations(search);
    }
  }

    // 地区
    const regions = ['', 'K', 'F', 'D', 'S'];

  // winning history modal
  const [winningStatusModalOpen, setWinningStatusModalOpen] = React.useState(false);
  function openWinningStatusModal(item) {
    const params = {
      bet_number_id: item.id
    };
    Utils.showSpinner();
    OrderFactory.getWinningHistoryByBetNumberId(params)
      .then(res => {
        if (res.status === 200) {
          setWinningHistories(res.data.winningHistories);
          setWinningStatusModalOpen(true);
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }
  function afterOpenWinningStatusModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeWinningStatusModal(){
    setWinningStatusModalOpen(false);
  }

  return (
    <div className="OrderInformation">
      <PageHeader title={'4. '+Lang.order_information} />
      {/*  */}
      <Modal
          isOpen={winningStatusModalOpen}
          onAfterOpen={afterOpenWinningStatusModal}
          onRequestClose={closeWinningStatusModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
        <div className="modal-body p-0">
          <div className="winning-status-table">
            <div className="tb-column">
              <div className="tb-row"></div>
              <div className="tb-row">{Lang.general_prizes_rank1_digit4}</div>
              <div className="tb-row">{Lang.general_prizes_rank2_digit4}</div>
              <div className="tb-row">{Lang.general_prizes_rank3_digit4}</div>
              <div className="tb-row">{Lang.general_prizes_rank1_digit3}</div>
              <div className="tb-row">{Lang.general_prizes_rank2_digit3}</div>
              <div className="tb-row">{Lang.general_prizes_rank3_digit3}</div>
              <div className="tb-row">{Lang.general_prizes_rank1_digit2}</div>
              <div className="tb-row">{Lang.general_prizes_rank2_digit2}</div>
              <div className="tb-row">{Lang.general_prizes_rank3_digit2}</div>
              <div className="tb-row">{Lang.grand_prizes_rank1_pool}</div>
              <div className="tb-row">{Lang.grand_prizes_rank2_pool}</div>
              <div className="tb-row">{Lang.grand_prizes_rank3_pool}</div>
              <div className="tb-row">{Lang.consolation_prizes}</div>
              <div className="tb-row">{Lang.total_prizes}</div>
            </div>
            {winningHistories && Object.values(winningHistories).map((item, index) =>
              <div className="tb-column" key={index}>
                <div className="tb-row">{item.region_name}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank1_digit4', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank2_digit4', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank3_digit4', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank1_digit3', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank2_digit3', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank3_digit3', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank1_digit2', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank2_digit2', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank3_digit2', 0)}</div>
                <div className="tb-row">{_.get(item, 'grand_prizes_digit4', 0)}</div>
                <div className="tb-row">{_.get(item, 'grand_prizes_digit3', 0)}</div>
                <div className="tb-row">{_.get(item, 'grand_prizes_digit2', 0)}</div>
                <div className="tb-row">{_.get(item, 'consolation_prizes', 0)}</div>
                <div className="tb-row">{_.get(item, 'total_prizes', 0)}</div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      {/*  */}
      <div className="card">
        <div className="card-header">
          <div className="row pt-1 pb-2">
            <div className="col-4">
              <div className="form-group d-flex m-0">
                {/*<label className="py-2 mr-2"> {Lang.machine_number}: </label>*/}
                <input type="text" className="form-control" style={{width: 150}}
                  placeholder={Lang.machine_number}
                  name="machine_number"
                  value={searchParam.machine_number}
                  onChange={($event) => handleChangeSearchParam($event.target)}
                />

                <input type="text" className="form-control ml-1" style={{width: 150}}
                       placeholder={Lang.number}
                       name="bet_number"
                       value={searchParam.bet_number}
                       onChange={($event) => handleChangeSearchParam($event.target)}
                />

                <input type="text" className="form-control ml-1" style={{width: 150}}
                       placeholder={Lang.serial_number}
                       name="serial_number"
                       value={searchParam.serial_number}
                       onChange={($event) => handleChangeSearchParam($event.target)}
                />
              </div>
            </div>
          </div>
          <div className="row mx-0 pt-1">
            <label className="py-2 mr-2">{Lang.time}: </label>
            <div className="form-group">
              <input type="date" className="form-control"
                name="start"
                max={searchParam.end}
                value={searchParam.start}
                onChange={($event) => handleChangeSearchParam($event.target)} />
            </div>
            <label className="p-2"> - </label>
            <div className="form-group">
              <input type="date" className="form-control"
                name="end"
                min={searchParam.start}
                // max={moment().tz('America/Caracas').format('YYYY-MM-DD')}
                max={moment().tz('America/Toronto').format('YYYY-MM-DD')}
                value={searchParam.end}
                onChange={($event) => handleChangeSearchParam($event.target)} />
            </div>
            <div className="form-group">
              <button type="button" className="btn btn-primary ml-3"
                onClick={() => getOrderInfomations(searchParam)}
              >{Lang.search}</button>
            </div>
            <div className="form-group">
              <ReactToPrint
                trigger={() => <button type="button" className="btn btn-primary ml-3">{Lang.print}</button>}
                content={() => componentRef.current}
              />
            </div>
          </div>
          <div className="row pt-1">
            <div className="col-4">
              <div className="form-group d-flex m-0">
                <label className="py-2 mr-2"> {Lang.winning_order}: </label>
                <input type="checkbox" className="m-2" style={{width: 20, height: 20}}
                  name="winning_order"
                  checked={searchParam.winning_order}
                  onChange={($event) => handleChangeSearchParam($event.target)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group d-flex m-0">
                <label className="py-2 mr-2"> {Lang.unpaid_order}: </label>
                <input type="checkbox" className="m-2" style={{width: 20, height: 20}}
                  name="unpaid_order"
                  checked={searchParam.unpaid_order}
                  onChange={($event) => handleChangeSearchParam($event.target)}
                />
              </div>
            </div>
            <div className="col-4">

            </div>
          </div>
          <div className="row pt-1">
            <div className="col-3">
              <div className="form-group d-flex m-0">
                <label className="py-2 mr-2"> Korsou </label>
                <input type="checkbox" className="m-2" style={{width: 20, height: 20}}
                  name="region_K"
                  checked={searchParam.region_K}
                  onChange={($event) => handleChangeSearchParam($event.target)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group d-flex m-0">
                <label className="py-2 mr-2"> Flamingo </label>
                <input type="checkbox" className="m-2" style={{width: 20, height: 20}}
                  name="region_F"
                  checked={searchParam.region_F}
                  onChange={($event) => handleChangeSearchParam($event.target)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group d-flex m-0">
                <label className="py-2 mr-2"> Diario </label>
                <input type="checkbox" className="m-2" style={{width: 20, height: 20}}
                  name="region_D"
                  checked={searchParam.region_D}
                  onChange={($event) => handleChangeSearchParam($event.target)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group d-flex m-0">
                <label className="py-2 mr-2"> ST </label>
                <input type="checkbox" className="m-2" style={{width: 20, height: 20}}
                  name="region_S"
                  checked={searchParam.region_S}
                  onChange={($event) => handleChangeSearchParam($event.target)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="row mx-auto" ref={componentRef}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>{Lang.machine_number}</th>
                  <th>{Lang.number}</th>
                  <th>{Lang.pool}</th>
                  <th>{Lang.amount}</th>
                  <th>{Lang.serial_number}</th>
                  <th>{Lang.order_time}</th>
                  <th>{Lang.drawing_time}</th>
                  <th>{Lang.region}</th>
                  <th>{Lang.winning_amount}</th>
                  <th>{Lang.winning_payment_time}</th>
                </tr>
              </thead>
              {orders && orders.map((item, index) =>
                <tbody key={index}>
                  {item.bet_numbers.length > 0 && item.bet_numbers.map((iitem, iindex) =>
                    <tr key={iindex}>
                      <td>{_.get(item, 'machine_number')}</td>
                      <td>{_.get(iitem, 'bet_number')}</td>
                      <td>{_.get(iitem, 'is_pool') ? 'pool' : ''}</td>
                      <td>{_.get(iitem, 'bet_money').toFixed(2)}</td>
                      <td>{_.get(item, 'serial_number')}</td>
                      {/*<td>{moment(item.created_at).tz('America/Caracas').format('YYYY-MM-DD HH:mm:ss')}</td>*/}
                      {/*<td>{moment(iitem.created_at).tz('America/Caracas').format('YYYY-MM-DD')}</td>*/}
                      <td>{moment(item.created_at).tz('America/Toronto').format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td>{moment(iitem.created_at).tz('America/Toronto').format('YYYY-MM-DD')}</td>
                      {/*<td>{_.get(item, 'belong_to')}</td>*/}
                      <td>{_.get(iitem, 'belong_to')}</td>
                      <td className={item.is_paid && iitem.prizes > 0 ? 'paid' : ''}>
                        <a onClick={() => openWinningStatusModal(iitem)}>{_.get(iitem, 'prizes').toFixed(2)}</a>
                      </td>
                      <td>{item.is_paid && iitem.prizes > 0 ? _.get(item, 'paid_at') : ''}</td>
                    </tr>
                  )}
                </tbody>
              )}
              <tfoot>
                <tr>
                  <th>{Lang.total}</th>
                  <th></th>
                  <th></th>
                  <th>{calcTotalBetMoney().toFixed(2)}</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{totalWinningAmount.toFixed(2)}</th>
                  <th></th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2" fillRule="evenodd"></path></svg>
          </button>
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17.707 9.293l-5-5a.999.999 0 1 0-1.414 1.414L14.586 9H3a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414" fillRule="evenodd"></path></svg>
          </button>
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default OrderInformation;
