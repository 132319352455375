import React from 'react';
import PageHeader from '../components/PageHeader';
import {MachineFactory, Utils} from '../../factory';
import Lang from '../../lang';

function Machines() {
  const [machines, setMachines] = React.useState([]);
  const [machineNumber, setMachineNumber] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [remark, setRemark] = React.useState('');

  React.useEffect(function() {
    const interval = setInterval(() => {
      getMachines();
    }, 5000);

    getMachines();

    return () => clearInterval(interval);
  }, []);

  const getMachines = () => {
    // Utils.showSpinner();
    MachineFactory.getMachines()
      .then(res => {
        if (res.status === 200) {
          setMachines(res.data);
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }

  const registerMachine = () => {
    const data = {
      machine_number: machineNumber,
      password: password,
      remark: remark
    };
    Utils.showSpinner();
    MachineFactory.registerMachine(data)
      .then(res => {
        getMachines();
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      })
  }

    const handleInputChange = (field, id, value) => {
        if(field === 'status'){
            if(!window.confirm(value === 1 ? Lang.confirm_start : Lang.confirm_stop)){
                return false;
            }
        }
        const data = {
            id: id,
            field: field,
            value: value
        };
        MachineFactory.updateMachine(data)
            .then(res => {
                getMachines();
                Utils.hideSpinner();
            })
            .catch(err => {
                console.log('err: ', err);
                Utils.hideSpinner();
            })
    }

  const deleteMachine = (machine) => {
    if(!window.confirm(Lang.confirm_delete)){
      return false;
    }
    const data = {
      id: machine.id
    };
    MachineFactory.deleteMachine(data)
      .then(res => {
        getMachines();
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      })
  }

  return (
    <div className="Machines">
      <PageHeader title={'9. '+Lang.set_account_password} />
      {/*  */}
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-3">
              <div className="row">
                {/*<label className="col-4 text-right py-2 m-0">{Lang.machine_number}: </label>*/}
                <input type="text" className="col-8 form-control"
                  placeholder={Lang.machine_number}
                  value={machineNumber}
                  onChange={(event) => setMachineNumber(event.target.value)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                {/*<label className="col-4 text-right py-2 m-0">{Lang.password}: </label>*/}
                <input type="text" className="col-8 form-control"
                  placeholder={Lang.password}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
            </div>
              <div className="col-3">
                <div className="row">
                  {/*<label className="col-4 text-right py-2 m-0">{Lang.remark}: </label>*/}
                  <input type="text" className="col-8 form-control"
                     placeholder={Lang.remark}
                     value={remark}
                     onChange={(event) => setRemark(event.target.value)}
                  />
                </div>
              </div>
            <div className="col-2">
              <button className="btn btn-primary"
                onClick={() => registerMachine()}
              >{Lang.register}</button>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <table className="table table-striped table-hover m-0">
            <thead className="">
              <tr>
                <th>No</th>
                <th>{Lang.machine_number}</th>
                <th>{Lang.password}</th>
                <th>{Lang.remark}</th>
                <th>{Lang.status}</th>
                <th>{Lang.operate}</th>
              </tr>
            </thead>
            <tbody>
              {
                machines && machines.map((item, index) =>
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.machine_number}</td>
                    <td>{item.password}</td>
                    <td style={{maxWidth:140}}><div><input className="border-0 w-100" type="text" name="remark" defaultValue={item.remark} onBlur={($event) => handleInputChange('remark',item.id,$event.target.value)} /></div></td>
                    <td>
                        {item.is_online == 1 && <font color='green'>{Lang.online}</font>}
                        {item.is_online == 0 && <font color="red">{Lang.offline}</font>}
                        {/*{item.is_online ? Lang.online : Lang.offline}*/}
                    </td>
                    <td>
                        {item.status === 1 && <button className="btn btn-outline-danger" onClick={() => handleInputChange('status', item.id, 2)}>{Lang.operate_stop}</button>}
                        {item.status === 2 && <button className="btn btn-danger" onClick={() => handleInputChange('status', item.id, 1)}>{Lang.operate_start}</button>}
                        &nbsp;
                      <button className="btn btn-danger"
                        onClick={() => deleteMachine(item)}
                      >{Lang.delete}</button>
                    </td>
                  </tr>
                )
              }

            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2" fillRule="evenodd"></path></svg>
          </button>
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17.707 9.293l-5-5a.999.999 0 1 0-1.414 1.414L14.586 9H3a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414" fillRule="evenodd"></path></svg>
          </button>
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default Machines;
