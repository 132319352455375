import React, { useRef } from 'react';
// import Moment from 'react-moment';
import moment from 'moment-timezone';
import ReactToPrint from 'react-to-print';
import PageHeader from '../components/PageHeader';
import TableOrderBy from '../components/TableOrderBy';
import {OrderFactory, Utils} from '../../factory';
import Lang from '../../lang';

function RealTimeOrderAcceptance() {
  const [isFirstLoading, setIsFirstLoading] = React.useState(true);
  const [betNumbers, setBetNumbers] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [searchParam, setSearchParam] = React.useState({
    machine_number: '',
    bet_number: '',
    serial_number: '',
    // start: moment().tz('America/Caracas').subtract(0, 'd').format('YYYY-MM-DD'),
    start: moment().tz('America/Toronto').subtract(0, 'd').format('YYYY-MM-DD'),
    // end: moment().tz('America/Caracas').format('YYYY-MM-DD'),
    end: moment().tz('America/Toronto').format('YYYY-MM-DD'),
    regions: '',
    order_column: 'order.created_at',
    order_by: 'DESC',
    order_base: 'order' // number | order
  });

  const componentRef = useRef();

  React.useEffect(function() {
    getRealTimeOrders(searchParam);
    const interval = setInterval(() => {
      getRealTimeOrders(searchParam);
    }, 10000);

    getRealTimeOrders(searchParam);

    return () => clearInterval(interval);
  }, [searchParam]);

  const getRealTimeOrders = (searchParam) => {
    // if(isFirstLoading) {
    //   setIsFirstLoading(false);
    //   Utils.showSpinner();
    // }
    OrderFactory.getRealTimeOrders(searchParam)
      .then(res => {
        if (res.status === 200) {
          setBetNumbers(res.data.betNumbers);
          setOrders(res.data.orders);
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }

  const calcTotalRevenue = () => {
    var revenue = 0;
    if(orders) {
      orders.map((item) => {
        if(!item.is_deleted) {
          item.bet_numbers.map((iitem) => {
            revenue += iitem.bet_money * iitem.belong_to.split('+').length;
          });
        }
      });
    }
    return revenue;
  }

  const handleSearchInput = (target) => {
    var search = {...searchParam};
    search[target.name] = target.value;
    setSearchParam(search);
  }

  const chooseRegionTab = (region) => {
    var search = {...searchParam};
    if(region === '') {
      search['regions'] = '';
    } else {
      if(search.regions.includes(region)) {
        search['regions'] = search.regions.split(region + ',').join('');
      } else {
        search['regions'] = search.regions + region + ',';
      }
    }
    setSearchParam(search);
    getRealTimeOrders(search);
  }

  const handleOrderBy = (column, orderBy) => {
    var search = {...searchParam};
    search["order_column"] = column;
    search["order_by"] = orderBy;
    if (column === 'bet_number' || column === 'is_pool' || column === 'bet_money') {
      search["order_base"] = 'number';
    } else {
      search["order_base"] = 'order';
    }
    setSearchParam(search);
    getRealTimeOrders(search);
  }

  // 地区
  const regions = ['', 'K', 'F', 'D', 'S'];

  return (
    <div className="RealTimeOrderAccpetance">
      <PageHeader title={'1. '+Lang.real_time_order_acceptance} />
      {/*  */}
      <div className="card">
        <div className="card-header">
          <div className="row mx-0 py-1">
            <div className="py-2 mr-2"> {Lang.total_revenue}: </div>
            <input type="text" className="form-control" style={{width: 200}} value={calcTotalRevenue()} readOnly />
          </div>
          <div className="row pt-1">
            {/* <div className="col-4">
              <div className="form-group d-flex m-0">
                <label className="py-2 mr-2"> {Lang.machine_number}: </label>
                <input type="text" className="form-control" style={{width: 200}}
                  name="machine_number"
                  value={searchParam.machine_number}
                  onChange={($event) => handleSearchInput($event.target)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group d-flex m-0">
                <label className="py-2 mr-2"> {Lang.number}: </label>
                <input type="text" className="form-control" style={{width: 200}}
                  name="bet_number"
                  value={searchParam.bet_number}
                  onChange={($event) => handleSearchInput($event.target)}
                />
              </div>
            </div> */}
            <div className="col-8">
              <div className="form-group d-flex m-0">
                <label className="py-2 mr-2 w-auto"> {Lang.serial_number}: </label>
                <input type="text" className="form-control" style={{width: 200}}
                  name="serial_number"
                  value={searchParam.serial_number}
                  onChange={($event) => handleSearchInput($event.target)}
                />
              </div>
            </div>
          </div>
          <div className="row mx-0 pt-1">
            <label className="py-2 mr-2">{Lang.time}: </label>
            <div className="form-group">
              <input type="date" className="form-control"
                name="start"
                max={searchParam.end}
                value={searchParam.start}
                onChange={($event) => handleSearchInput($event.target)} />
            </div>
            <label className="p-2"> - </label>
            <div className="form-group">
              <input type="date" className="form-control"
                name="end"
                min={searchParam.start}
                // max={moment().tz('America/Caracas').format('YYYY-MM-DD')}
                max={moment().tz('America/Toronto').format('YYYY-MM-DD')}
                value={searchParam.end}
                onChange={($event) => handleSearchInput($event.target)} />
            </div>
            <div className="form-group">
              <button type="button" className="btn btn-primary ml-3"
                onClick={() => getRealTimeOrders(searchParam)}
              >{Lang.search}</button>
            </div>
            <div className="form-group">
              <ReactToPrint
                trigger={() => <button type="button" className="btn btn-primary ml-3">{Lang.print}</button>}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="row mx-0 p-1">
            <button className={searchParam.regions === '' ? "btn btn-primary m-2" : "btn btn-outline-primary m-2"}
              onClick={() => chooseRegionTab('')}
            >{Lang.all}</button>
            <button className={searchParam.regions.includes('K') ? "btn btn-primary m-2" : "btn btn-outline-primary m-2"}
              onClick={() => chooseRegionTab('K')}
            >Korsou</button>
            <button className={searchParam.regions.includes('F') ? "btn btn-primary m-2" : "btn btn-outline-primary m-2"}
              onClick={() => chooseRegionTab('F')}
            >Flamingo</button>
            <button className={searchParam.regions.includes('D') ? "btn btn-primary m-2" : "btn btn-outline-primary m-2"}
              onClick={() => chooseRegionTab('D')}
            >Diario</button>
            <button className={searchParam.regions.includes('S') ? "btn btn-primary m-2" : "btn btn-outline-primary m-2"}
              onClick={() => chooseRegionTab('S')}
            >ST</button>
          </div>
          <div className="row mx-auto" ref={componentRef}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>{Lang.machine_number}<TableOrderBy searchParam={searchParam} column="machine_number" handleOrderBy={handleOrderBy} /></th>
                  <th>{Lang.number}<TableOrderBy searchParam={searchParam} column="bet_number" handleOrderBy={handleOrderBy} /></th>
                  <th>{Lang.pool}<TableOrderBy searchParam={searchParam} column="is_pool" handleOrderBy={handleOrderBy} /></th>
                  <th>{Lang.amount}<TableOrderBy searchParam={searchParam} column="bet_money" handleOrderBy={handleOrderBy} /></th>
                  <th>{Lang.serial_number}<TableOrderBy searchParam={searchParam} column="serial_number" handleOrderBy={handleOrderBy} /></th>
                  <th>{Lang.order_time}<TableOrderBy searchParam={searchParam} column="order.created_at" handleOrderBy={handleOrderBy} /></th>
                  <th>{Lang.drawing_time}<TableOrderBy searchParam={searchParam} column="created_at" handleOrderBy={handleOrderBy} /></th>
                  <th>{Lang.region}<TableOrderBy searchParam={searchParam} column="belong_to" handleOrderBy={handleOrderBy} /></th>
                  <th>{Lang.deleted_time}<TableOrderBy searchParam={searchParam} column="updated_at" handleOrderBy={handleOrderBy} /></th>
                </tr>
              </thead>
              <tbody>
                {searchParam.order_base === 'number' && betNumbers && betNumbers.map((item, index) =>
                  <tr key={index}>
                    <td>{item.order.machine_number}</td>
                    <td>{item.bet_number}</td>
                    <td>{item.is_pool ? 'pool' : ''}</td>
                    <td>{item.bet_money.toFixed(2)}</td>
                    <td>{item.order.serial_number}</td>
                    {/*<td>{moment(item.order.created_at).tz('America/Caracas').format('YYYY-MM-DD HH:mm:ss')}</td>*/}
                    <td>{moment(item.order.created_at).tz('America/Toronto').format('YYYY-MM-DD HH:mm:ss')}</td>
                    {/*<td>{moment(item.created_at).tz('America/Caracas').format('YYYY-MM-DD')}</td>*/}
                    <td>{moment(item.created_at).tz('America/Toronto').format('YYYY-MM-DD')}</td>
                    <td>{item.belong_to}</td>
                    <td>{item.order.deleted_at}</td>
                  </tr>
                )}
              </tbody>
              {searchParam.order_base === 'order' && orders && orders.map((item, index) =>
                <tbody key={index}>
                {item.bet_numbers && item.bet_numbers.map((iitem, iindex) =>
                  <tr key={iindex}>
                    <td>{item.machine_number}</td>
                    <td>{iitem.bet_number}</td>
                    <td>{iitem.is_pool ? 'pool' : ''}</td>
                    <td>{iitem.bet_money.toFixed(2)}</td>
                    <td>{item.serial_number}</td>
                    {/*<td>{moment(item.created_at).tz('America/Caracas').format('YYYY-MM-DD HH:mm:ss')}</td>*/}
                    <td>{moment(item.created_at).tz('America/Toronto').format('YYYY-MM-DD HH:mm:ss')}</td>
                    {/*<td>{moment(iitem.created_at).tz('America/Caracas').format('YYYY-MM-DD')}</td>*/}
                    <td>{moment(iitem.created_at).tz('America/Toronto').format('YYYY-MM-DD')}</td>
                    <td>{iitem.belong_to}</td>
                    <td>{item.deleted_at}</td>
                  </tr>
                )}
                </tbody>
              )}
            </table>
          </div>
        </div>
        <div className="card-footer">
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2" fillRule="evenodd"></path></svg>
          </button>
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17.707 9.293l-5-5a.999.999 0 1 0-1.414 1.414L14.586 9H3a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414" fillRule="evenodd"></path></svg>
          </button>
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default RealTimeOrderAcceptance;
