import React, { useRef } from 'react';
// import Moment from 'react-moment';
import moment from 'moment-timezone';
import _ from "lodash";
import ReactToPrint from 'react-to-print';
import PageHeader from '../components/PageHeader';
import TableOrderBy from '../components/TableOrderBy';
import {OrderFactory, MachineFactory, Utils} from '../../factory';
import Lang from '../../lang';
import Modal from 'react-modal';
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : 0
  }
};

function TotalOrder() {
  // commission modal
  const [commissionOpen, setCommissionOpen] = React.useState(false);
  function openCommissionModal() {
    setCommissionOpen(true);
  }
  function afterOpenCommissionModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeCommissionModal(){
    setCommissionOpen(false);
  }
  // winning status detail modal
  const [winningStatusModalOpen, setWinningStatusModalOpen] = React.useState(false);
  function openWinningStatusModal(item) {
    const params = {
      machine_number: item.machine_number,
      start: item.date ? item.date : searchParam.start,
      end: item.date ? item.date : searchParam.end
    };
    Utils.showSpinner();
    OrderFactory.getWinningHistory(params)
      .then(res => {
        if (res.status === 200) {
          setWinningHistories(res.data.winningHistories);
          setWinningStatusModalOpen(true);
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }
  function afterOpenWinningStatusModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeWinningStatusModal(){
    setWinningStatusModalOpen(false);
  }

  //
  const [winningHistories, setWinningHistories] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [commissions, setCommissions] = React.useState([]);
  const [modalCommissions, setModalCommissions] = React.useState([]);
  const [searchParam, setSearchParam] = React.useState({
    machine_number: '',
    all_machines: true,
    // start: moment().tz('America/Caracas').subtract(0, 'd').format('YYYY-MM-DD'),
    start: moment().tz('America/Toronto').subtract(0, 'd').format('YYYY-MM-DD'),
    // end: moment().tz('America/Caracas').format('YYYY-MM-DD'),
    end: moment().tz('America/Toronto').format('YYYY-MM-DD'),
    order_column: 'machine_number',
    order_by: 'ASC',
  });
  const [showDate, setShowDate] = React.useState(true);

  const componentRef = useRef();

  React.useEffect(function() {
    getTotalOrders(searchParam);
    getCommissions();
  }, []);

  const getTotalOrders = (searchParam) => {
    Utils.showSpinner();
    OrderFactory.getTotalOrders(searchParam)
      .then(res => {
        if (res.status === 200) {
          setOrders(res.data.orders);
          setShowDate(searchParam.start === searchParam.end || searchParam.machine_number !== '');
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }

  const getCommissions = () => {
    Utils.showSpinner();
    MachineFactory.getCommissions()
      .then(res => {
        if (res.status === 200) {
          setCommissions(res.data.commissions);
          setModalCommissions(res.data.commissions);
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }

  const handleSearchInput = (target) => {
    var search = {...searchParam};
    search[target.name] = target.type === "checkbox" ? target.checked : target.value;
    if (target.name === "all_machines" && target.checked) {
      search['machine_number'] = '';
    }
    if (target.name === "machine_number" && target.value !== '') {
      search['all_machines'] = false;
    }
    setSearchParam(search);
  }

  const handleOrderBy = (column, orderBy) => {
    var search = {...searchParam};
    search["order_column"] = column;
    search["order_by"] = orderBy;
    setSearchParam(search);
  }

  const handleCommissionChange = (target, index) => {
    const commissionArr = [...commissions];
    const commissionItem = {...commissionArr[index]};
    commissionItem[target.name] = target.value.split('%').join('');
    commissionArr[index] = commissionItem;
    setModalCommissions(commissionArr);
  }

  const saveCommissions = () => {
    MachineFactory.saveCommissionsChange({commissions: modalCommissions})
      .then(res => {
        if (res.status === 200) {
          getTotalOrders(searchParam);
          setCommissions(modalCommissions);
          closeCommissionModal();
        } else {
          setModalCommissions(commissions);
        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
        setModalCommissions(commissions);
      });
  }

  const calcTotalSettlement = () => {
    if (!orders.length){
      return 0;
    }
      var totalSettlement = 0;
      orders.map((item) => {
        totalSettlement += _.round(_.get(item, 'sub_total_bet_money') * (1 - _.get(item, 'commission') / 100) - _.get(item, 'prizes'), 2);
      })
      return _.round(totalSettlement * 100) / 100;
  }

  const calcTotalPrizes = () => {
    if (!orders.length){
      return 0;
    }
      return _.sumBy(orders, 'prizes');
  }

  const calcTotalRedemptionAmount = () => {
    if (!orders.length){
      return 0;
    }
      return _.sumBy(orders, 'redemption_amount');
  }

  const calcTotalCommissionAmount = () => {
    if (!orders.length){
      return 0;
    }
      var totalCommissionAmount = 0;
      orders.map((item) => {
        totalCommissionAmount += _.get(item, 'sub_total_bet_money') * _.get(item, 'commission') / 100;
      })
      return _.round(totalCommissionAmount * 100) / 100;
  }

  const calcTotalBetMoney = () => {
    if (!orders.length){
      return 0;
    }
      return _.sumBy(orders, 'sub_total_bet_money');
  }

  function desc(a, b, orderBy) {
    if (_.get(b, orderBy) < _.get(a, orderBy)) {
      return -1;
    }
    if (_.get(b, orderBy) > _.get(a, orderBy)) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "DESC"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  return (
    <div className="TotalOrder">
      <PageHeader title={'3. '+Lang.total_order} />
      {/*  */}
      <Modal
          isOpen={commissionOpen}
          onAfterOpen={afterOpenCommissionModal}
          onRequestClose={closeCommissionModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
        <div className="modal-header">
          <h5 className="text-center m-0">{Lang.commission_setting}</h5>
          <button className="close" onClick={saveCommissions}>{Lang.confirm}</button>
        </div>
        <div className="modal-body p-0">
          <table className="table table-bordered m-0">
            <thead>
              <tr>
                <th>{Lang.machine_number}<TableOrderBy searchParam={searchParam} column="machine_number" handleOrderBy={handleOrderBy} /></th>
                <th>{Lang.commission}<TableOrderBy searchParam={searchParam} column="commission" handleOrderBy={handleOrderBy} /></th>
              </tr>
            </thead>
            <tbody>
            {modalCommissions && stableSort(modalCommissions, getSorting(searchParam.order_by, searchParam.order_column)).map((item, index) =>
              <tr key={index}>
                <td className="py-2 px-3" style={{height: 38, minWidth: 100}}>{item.machine_number}</td>
                <td className="p-0" style={{maxWidth: 100}}>
                  <input type="text" className="form-control w-100 border-0"
                    name="commission"
                    value={item.commission + '%'}
                    onChange={($event) => handleCommissionChange($event.target, index)}
                  />
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
      </Modal>
      {/*  */}
      <Modal
          isOpen={winningStatusModalOpen}
          onAfterOpen={afterOpenWinningStatusModal}
          onRequestClose={closeWinningStatusModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
        <div className="modal-body p-0">
          <div className="winning-status-table">
            <div className="tb-column">
              <div className="tb-row"></div>
              <div className="tb-row">{Lang.general_prizes_rank1_digit4}</div>
              <div className="tb-row">{Lang.general_prizes_rank2_digit4}</div>
              <div className="tb-row">{Lang.general_prizes_rank3_digit4}</div>
              <div className="tb-row">{Lang.general_prizes_rank1_digit3}</div>
              <div className="tb-row">{Lang.general_prizes_rank2_digit3}</div>
              <div className="tb-row">{Lang.general_prizes_rank3_digit3}</div>
              <div className="tb-row">{Lang.general_prizes_rank1_digit2}</div>
              <div className="tb-row">{Lang.general_prizes_rank2_digit2}</div>
              <div className="tb-row">{Lang.general_prizes_rank3_digit2}</div>
              <div className="tb-row">{Lang.grand_prizes_rank1_pool}</div>
              <div className="tb-row">{Lang.grand_prizes_rank2_pool}</div>
              <div className="tb-row">{Lang.grand_prizes_rank3_pool}</div>
              <div className="tb-row">{Lang.consolation_prizes}</div>
              <div className="tb-row">{Lang.total_prizes}</div>
            </div>
            {winningHistories && Object.values(winningHistories).map((item, index) =>
              <div className="tb-column" key={index}>
                <div className="tb-row">{item.region_name}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank1_digit4', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank2_digit4', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank3_digit4', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank1_digit3', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank2_digit3', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank3_digit3', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank1_digit2', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank2_digit2', 0)}</div>
                <div className="tb-row">{_.get(item, 'general_prizes_rank3_digit2', 0)}</div>
                <div className="tb-row">{_.get(item, 'grand_prizes_digit4', 0)}</div>
                <div className="tb-row">{_.get(item, 'grand_prizes_digit3', 0)}</div>
                <div className="tb-row">{_.get(item, 'grand_prizes_digit2', 0)}</div>
                <div className="tb-row">{_.get(item, 'consolation_prizes', 0)}</div>
                <div className="tb-row">{_.get(item, 'total_prizes', 0)}</div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      {/*  */}
      <div className="card">
        <div className="card-header">
          <div className="row pt-1">
            <div className="col-8">
              <div className="form-group d-flex m-0">
                {/*<label className="py-2 mr-2"> {Lang.machine_number}: </label>*/}
                <input type="text" className="form-control mr-4" style={{width: 200}}
                  placeholder={Lang.machine_number}
                  name="machine_number"
                  value={searchParam.machine_number}
                  onChange={($event) => handleSearchInput($event.target)}
                />
                  <label className="py-2 mr-2"> {Lang.all_machines} </label>
                  <input type="checkbox" className="m-2" style={{width: 20, height: 20}}
                         name="all_machines"
                         checked={searchParam.all_machines}
                         onChange={($event) => handleSearchInput($event.target)}
                  />
              </div>
            </div>
          </div>
          <div className="row mx-0 pt-1">
            <label className="py-2 mr-2">{Lang.time}: </label>
            <div className="form-group">
              <input type="date" className="form-control"
                name="start"
                max={searchParam.end}
                value={searchParam.start}
                onChange={($event) => handleSearchInput($event.target)} />
            </div>
            <label className="p-2"> - </label>
            <div className="form-group">
              <input type="date" className="form-control"
                name="end"
                min={searchParam.start}
                // max={moment().tz('America/Caracas').format('YYYY-MM-DD')}
                max={moment().tz('America/Toronto').format('YYYY-MM-DD')}
                value={searchParam.end}
                onChange={($event) => handleSearchInput($event.target)} />
            </div>
            <div className="form-group">
              <button type="button" className="btn btn-primary ml-3"
                onClick={() => getTotalOrders(searchParam)}
              >{Lang.search}</button>
            </div>
            <div className="form-group">
              <ReactToPrint
                trigger={() => <button type="button" className="btn btn-primary ml-3">{Lang.print}</button>}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="row mx-auto" ref={componentRef}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>{Lang.machine_number}</th>
                  <th>{Lang.remark}</th>
                  {showDate ? <th>{Lang.date}</th> : null}
                  <th>{Lang.sales}</th>
                  <th><a onClick={openCommissionModal}>{Lang.commission}</a></th>
                  <th>{Lang.winning_amount}</th>
                  <th>{Lang.redemption_amount}</th>
                  <th>{Lang.settlement}</th>
                </tr>
              </thead>
              <tbody>
                {orders && orders.map((item, index) =>
                  <tr key={index}>
                    <td>{_.get(item, 'machine_number')}</td>
                    <td>{_.get(item, 'machine_remark')}</td>
                    {showDate ? <td>{_.get(item, 'date')}</td> : null}
                    <td>{_.get(item, 'sub_total_bet_money').toFixed(2)}</td>
                    <td>{(_.round(_.get(item, 'sub_total_bet_money') * _.get(item, 'commission')) / 100).toFixed(2)}({_.get(item, 'commission')}%)</td>
                    <td><a onClick={() => openWinningStatusModal(item)}>{_.get(item, 'prizes').toFixed(2)}</a></td>
                    <td>{_.get(item, 'redemption_amount').toFixed(2)}</td>
                    <td>{_.round(_.get(item, 'sub_total_bet_money') * (1 - _.get(item, 'commission') / 100) - _.get(item, 'prizes'), 2).toFixed(2)}</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th>{Lang.total}</th>
                  <th></th>
                  {showDate ? <th></th> : null}
                  <th>{calcTotalBetMoney().toFixed(2)}</th>
                  <th>{calcTotalCommissionAmount().toFixed(2)}</th>
                  <th>{calcTotalPrizes().toFixed(2)}</th>
                  <th>{calcTotalRedemptionAmount().toFixed(2)}</th>
                  <th>{calcTotalSettlement().toFixed(2)}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2" fillRule="evenodd"></path></svg>
          </button>
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17.707 9.293l-5-5a.999.999 0 1 0-1.414 1.414L14.586 9H3a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414" fillRule="evenodd"></path></svg>
          </button>
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default TotalOrder;
