import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

//
import Header from './components/Layouts/Header';
import Leftbar from './components/Layouts/Leftbar';
import Login from './Auth/Login';
// import Dashboard from './Dashboard/Dashboard';

import RealTimeOrderAcceptance from './RealTimeOrderAcceptance/RealTimeOrderAcceptance';
import NumberDistribution from './NumberDistribution/NumberDistribution';
import TotalOrder from './TotalOrder/TotalOrder';
import OrderInformation from './OrderInformation/OrderInformation';
import WinningLotteryNumber from './WinningLotteryNumber/WinningLotteryNumber';
import NumberMoneyLimit from './NumberMoneyLimit/NumberMoneyLimit';
import RegionalTimeLimit from './RegionalTimeLimit/RegionalTimeLimit';
import OnlineStatus from './OnlineStatus/OnlineStatus';
import Machines from './Machines/Machines';
import Settings from './Settings/Settings';
import { AuthFactory } from '../factory';
import { EventEmitter } from '../events';
import env from "../env";
import {POST} from "../factory/Utils";
import "../factory/AuthFactory";

function Admin() {

    function checkToken(){
        AuthFactory.checkToken({"token": AuthFactory.getToken(), "name": AuthFactory.getDisplayName()})
            .then((res) => {
                if(res.status !== 200 || !res.data.check_res){
                    localStorage.clear();
                    EventEmitter.dispatch('refreshLogin', false);
                }
                return true;
            });
    }

    checkToken();

  const [isLoggedin, setIsLoggedin] = React.useState(true);

  EventEmitter.subscribe('refreshLogin', (event) => {
    setIsLoggedin(event);
  });

  React.useEffect(() => {
    let loginStatus = AuthFactory.getAdminLoginStatus();
    setIsLoggedin(loginStatus);
  }, []);

    return (
      <Router>
        <div className="App-Admin">
          <Switch>
            <Route path="/login">
              <AdminAuth />
            </Route>
            <Route path="/">
              {isLoggedin ? <AdminPanel /> : <AdminAuth />}
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }

  export default Admin;

  function AdminAuth () {
    return (
      <Router>
        <Switch>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </Router>
    );
  }

  function AdminPanel () {
    return (
        <Router>
          <div className="Admin-panel">
            {/* Header */}
            <Header />
            {/* LeftNav */}
            <Leftbar />

            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            <div className="App-main">
              <div className="container-fluid">
                <Switch>
                  <Route path="/settings">
                    <Settings />
                  </Route>
                  <Route path="/machines">
                    <Machines />
                  </Route>
                  <Route path="/online-status">
                    <OnlineStatus />
                  </Route>
                  <Route path="/regional-time-limit">
                    <RegionalTimeLimit />
                  </Route>
                  <Route path="/number-money-limit">
                    <NumberMoneyLimit />
                  </Route>
                  <Route path="/winning-lottery-number">
                    <WinningLotteryNumber />
                  </Route>
                  <Route path="/order-information">
                    <OrderInformation />
                  </Route>
                  <Route path="/total-order">
                    <TotalOrder />
                  </Route>
                  <Route path="/number-distribution">
                    <NumberDistribution />
                  </Route>
                  <Route path="/real-time-orders">
                    <RealTimeOrderAcceptance />
                  </Route>
                  {/* <Route path="/login">
                    <Login />
                  </Route> */}
                  <Route path="/">
                    <RealTimeOrderAcceptance />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </Router>
    );
  }
