import React from 'react';
import moment from 'moment-timezone';
import { AuthFactory, OrderFactory, Utils } from '../../factory';
import PageHeader from '../components/PageHeader';
import Lang from '../../lang';

function Settings() {

  const [adminUser, setAdminUser] = React.useState(null);
  const [oldPassword, setOldPassword] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const [dateRange, setDateRange] = React.useState({
    // start: moment().tz('America/Caracas').subtract(90, 'd').format('YYYY-MM-DD'),
    // end: moment().tz('America/Caracas').subtract(30, 'd').format('YYYY-MM-DD'),
    start: '',
    end: ''
  });

  React.useEffect(() => {
    const _loginUser = AuthFactory.getLoginUserData();
    setAdminUser(_loginUser);
  }, []);

  const updatePassword = () => {
    const data = {
      id: adminUser.id,
      oldpwd: oldPassword,
      password: password,
      password_confirmation: passwordConfirmation
    };
    Utils.showSpinner();
    AuthFactory.updatePassword(data)
      .then(res => {
        console.log('res: ', res);
        if (res.status === 200) {
          AuthFactory.saveLoginUserInfo(res.data);
          Utils.showToast('success', 1500, res.message, '');
        } else {
          Utils.showToast('warning', 1500, res.message, '');
        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.showToast('error', 1500, "Error Occurred.", '');
        Utils.hideSpinner();
      })
  }

  const handleDateRangeInput = (target) => {
    var _dateRange = {...dateRange};
    _dateRange[target.name] = target.value;
    setDateRange(_dateRange);
  }

  const removeData = () => {
    if(!window.confirm(Lang.confirm_delete)){
      return false;
    }
    OrderFactory.removeOrderData(dateRange)
      .then(res => {
        if (res.status === 200) {
          Utils.showToast('success', 1500, res.message, '');
        } else {
          Utils.showToast('warning', 1500, res.message, '');
        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.showToast('error', 1500, "Error Occurred.", '');
        Utils.hideSpinner();
      })
  }

  return (
    <div className="Settings">
      <PageHeader title={'10. '+Lang.platform_settings} />
      {/*  */}
      <div className="card">
        {/* <div className="card-header d-flex">
          <h6>{Lang.platform_settings}</h6>
        </div> */}
        <div className="card-body">
          <div className="row mt-4">
            <div className="col-8">
              <div className="row">
                <label className="col-6 text-right pr-5 py-2 m-0">{Lang.platform_access_account}</label>
                <div className="col-6 form-control">{adminUser && adminUser.username}</div>
              </div>
              <div className="row pl-5 my-3">
                <h5 className="w-100">{Lang.Change_Password}</h5>
              </div>
              <div className="row mt-2">
                <label className="col-6 text-right pr-5 py-2 m-0">{Lang.old_password} *</label>
                <div className="col-6 p-0">
                  <input type="password" className="form-control w-100"
                    value={oldPassword}
                    onChange={(event) => setOldPassword(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <label className="col-6 text-right pr-5 py-2 m-0">{Lang.new_password} *</label>
                <div className="col-6 p-0">
                  <input type="password" className="form-control w-100"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <small className="w-100">{Lang.password_validation_message}</small>
                </div>
              </div>
              <div className="row mt-2">
                <label className="col-6 text-right pr-5 py-2 m-0">{Lang.new_password_confirmation} *</label>
                <div className="col-6 p-0">
                  <input type="password" className="form-control w-100"
                    value={passwordConfirmation}
                    onChange={(event) => setPasswordConfirmation(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <button type="button" className="btn btn-primary ml-auto"
                  onClick={() => updatePassword()}
                >{Lang.Save_Changes}</button>
              </div>
              <hr />
              <div className="row pl-5 my-3">
                <h5 className="w-100">{Lang.platform_data_remove}</h5>
              </div>
              <div className="row pt-1">
                <label className="py-2 mr-2 ml-auto">{Lang.time}: </label>
                <div className="form-group">
                  <input type="date" className="form-control"
                    name="start"
                    // max={dateRange.end}
                    value={dateRange.start}
                    onChange={($event) => handleDateRangeInput($event.target)} />
                </div>
                <label className="p-2"> - </label>
                <div className="form-group">
                  <input type="date" className="form-control"
                    name="end"
                    // min={dateRange.start}
                    // max={moment().tz('America/Caracas').format('YYYY-MM-DD')}
                    value={dateRange.end}
                    onChange={($event) => handleDateRangeInput($event.target)} />
                </div>
                <div className="form-group">
                  <button type="button" className="btn btn-primary ml-3"
                    onClick={() => removeData(dateRange)}
                  >{Lang.delete_immediately}</button>
                </div>
              </div>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default Settings;
