import React from 'react';
import PageHeader from '../components/PageHeader';
import {RegionFactory, Utils} from '../../factory';
import Lang from '../../lang';

function RegionalTimeLimit() {
  const [regions, setRegions] = React.useState([]);

  React.useEffect(function() {
    getRegions();
  }, []);

  const getRegions = () => {
    Utils.showSpinner();
    RegionFactory.getRegions()
      .then(res => {
        if (res.status === 200) {
          setRegions(res.data);
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }

  const handleInputChange = (item, index, target) => {
    const regionArr = [...regions];
    const regionItem = {...regionArr[index]};
    regionItem[target.name] = target.name.includes('disable') ? target.checked : target.value;
    regionArr[index] = regionItem;
    setRegions(regionArr);
    saveRegionTimeLimit(regionArr[index]);
  }

  const saveRegionTimeLimit = (region) => {
    Utils.showSpinner();
    RegionFactory.saveRegionTimeLimit(region)
    .then(res => {
      if (res.status === 200) {
        setRegions(res.data);
      } else {

      }
      Utils.hideSpinner();
    })
    .catch(err => {
      console.log('err: ', err);
      Utils.hideSpinner();
    });
  }

  return (
    <div className="RegionalTimeLimit">
      <PageHeader title={'7. '+Lang.regional_time_limit} />
      {/*  */}
      <div className="card">
        <div className="card-body p-0">
          <table className="table table-striped m-0">
            <thead className="">
              <tr>
                <th className="text-center"></th>
                <th className="text-center">{Lang.monday}</th>
                <th className="text-center">{Lang.tuesday}</th>
                <th className="text-center">{Lang.wednesday}</th>
                <th className="text-center">{Lang.thursday}</th>
                <th className="text-center">{Lang.friday}</th>
                <th className="text-center">{Lang.saturday}</th>
                <th className="text-center">{Lang.sunday}</th>
              </tr>
            </thead>
            {
              regions && regions.map((item, index) =>
                <tbody key={index}>
                  <tr><td colSpan="8" className="text-center">{item.region_name}</td></tr>
                  <tr>
                    <td className="p-0">
                      <div className="text-center">{Lang.start}</div>
                      <div className="text-center">{Lang.end}</div>
                      <div className="text-center">{Lang.disable}</div>
                    </td>
                    <td className="p-0">
                      <div className="text-center"><input type="time" name="start1" value={item.start1} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="time" name="end1" value={item.end1} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="checkbox" name="disable1" checked={item.disable1} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                    </td>
                    <td className="p-0">
                      <div className="text-center"><input type="time" name="start2" value={item.start2} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="time" name="end2" value={item.end2} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="checkbox" name="disable2" checked={item.disable2} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                    </td>
                    <td className="p-0">
                      <div className="text-center"><input type="time" name="start3" value={item.start3} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="time" name="end3" value={item.end3} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="checkbox" name="disable3" checked={item.disable3} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                    </td>
                    <td className="p-0">
                      <div className="text-center"><input type="time" name="start4" value={item.start4} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="time" name="end4" value={item.end4} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="checkbox" name="disable4" checked={item.disable4} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                    </td>
                    <td className="p-0">
                      <div className="text-center"><input type="time" name="start5" value={item.start5} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="time" name="end5" value={item.end5} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="checkbox" name="disable5" checked={item.disable5} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                    </td>
                    <td className="p-0">
                      <div className="text-center"><input type="time" name="start6" value={item.start6} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="time" name="end6" value={item.end6} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="checkbox" name="disable6" checked={item.disable6} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                    </td>
                    <td className="p-0">
                      <div className="text-center"><input type="time" name="start0" value={item.start0} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="time" name="end0" value={item.end0} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                      <div className="text-center"><input type="checkbox" name="disable0" checked={item.disable0} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                    </td>
                  </tr>
                </tbody>
              )
            }

          </table>
        </div>
        <div className="card-footer">
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2" fillRule="evenodd"></path></svg>
          </button>
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17.707 9.293l-5-5a.999.999 0 1 0-1.414 1.414L14.586 9H3a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414" fillRule="evenodd"></path></svg>
          </button>
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default RegionalTimeLimit;
