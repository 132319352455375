import React from 'react';
import PageHeader from '../components/PageHeader';
import {RegionFactory, Utils} from '../../factory';
import Lang from '../../lang';

function NumberMoneyLimit() {
  const [regions, setRegions] = React.useState([]);
  const [specialNumInputs, setSpecialNumInputs] = React.useState([]);

  React.useEffect(function() {
    getRegions();
  }, []);

  const getRegions = () => {
    Utils.showSpinner();
    RegionFactory.getRegions()
      .then(res => {
        if (res.status === 200) {
          setRegions(res.data);
          initSpecialNumInputs(res.data);
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }

  const initSpecialNumInputs = (data) => {
    var arr = [...specialNumInputs];
    for(var i in data) {
      var specialInput = {
        region_id: data[i].id,
        number: '',
        limit: ''
      }
      // arr.push(specialInput);
      arr[i] = specialInput;
    }
    setSpecialNumInputs(arr);
  }

  const handleInputChange = (item, index, target) => {
    const regionArr = [...regions];
    const regionItem = {...regionArr[index]};
    regionItem[target.name] = target.name.includes('disable') ? target.checked : target.value;
    regionArr[index] = regionItem;
    setRegions(regionArr);
  }

  const saveRegionTimeLimit = (index) => {
    const region = regions[index];
    Utils.showSpinner();
    RegionFactory.saveRegionTimeLimit(region)
    .then(res => {
      console.log('res: ', res.data[0]);
      if (res.status === 200) {
        setRegions(res.data);
      } else {

      }
      Utils.hideSpinner();
    })
    .catch(err => {
      console.log('err: ', err);
      Utils.hideSpinner();
    });
  }

  const handleSpecialNumInput = (index, target) => {
    const specialNumInputArr = [...specialNumInputs];
    const specialNumInput = {...specialNumInputArr[index]};
    specialNumInput[target.name] = target.value;
    specialNumInputArr[index] = specialNumInput;
    setSpecialNumInputs(specialNumInputArr);
  }

  const addSpecialNumber = (index) => {
    if(specialNumInputs[index].number.length > 4 && specialNumInputs[index].number.slice(-1).toLowerCase() != 'p') {
      Utils.showToast('warning', 1500, 'Invalid params', '');
      return;
    }
    Utils.showSpinner();
    RegionFactory.addSpecialNumber(specialNumInputs[index])
      .then(res => {
        if (res.status === 200) {
          setRegions(res.data);
          initSpecialNumInputs(res.data);
          Utils.showToast('success', 1500, res.message, '');
        } else {
          Utils.showToast('warning', 1500, res.message, '');
        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }

  const deleteSpecialNumber = (specialNumber) => {
    Utils.showSpinner();
    RegionFactory.deleteSpecialNumber(specialNumber)
      .then(res => {
        if (res.status === 200) {
          setRegions(res.data);
          initSpecialNumInputs(res.data);
        } else {

        }
        Utils.hideSpinner();
      })
      .catch(err => {
        console.log('err: ', err);
        Utils.hideSpinner();
      });
  }

  return (
    <div className="NumberMoneyLimit">
      <PageHeader title={'6. '+Lang.number_limit} />
      {/*  */}
      <div className="card">
        <div className="card-body p-0">
          {regions && regions.map((item, index) =>
            <div className="number-money-limit-section" key={index}>
              <h5 className="font-weight-bold p-2 mb-0 mt-2">{item.region_name}</h5>
              <div className="row section-row p-1">
                <div className="col-8 section-left">
                  <div className="row px-1 py-2">
                    <div className="col-3">{Lang.number_digits}</div>
                    <div className="col-3">{Lang.limit}</div>
                    <div className="col-3">Pool</div>
                    <div className="col-3">{Lang.limit}</div>
                  </div>
                  <div className="row p-1">
                    <div className="col-3 py-1">0000 ~ 9999</div>
                    <div className="col-3"><input type="text" className="form-control" name="digit4_limit" value={item.digit4_limit} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                    <div className="col-3 py-1">0000 ~ 9999</div>
                    <div className="col-3"><input type="text" className="form-control" name="digit4_pool_limit" value={item.digit4_pool_limit} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                  </div>
                  <div className="row p-1">
                    <div className="col-3 py-1">000 ~ 999</div>
                    <div className="col-3"><input type="text" className="form-control" name="digit3_limit" value={item.digit3_limit} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                    <div className="col-3 py-1">000 ~ 999</div>
                    <div className="col-3"><input type="text" className="form-control" name="digit3_pool_limit" value={item.digit3_pool_limit} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                  </div>
                  <div className="row p-1">
                    <div className="col-3 py-1">00 ~ 99</div>
                    <div className="col-3"><input type="text" className="form-control" name="digit2_limit" value={item.digit2_limit} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                    <div className="col-3 py-1">00 ~ 99</div>
                    <div className="col-3"><input type="text" className="form-control" name="digit2_pool_limit" value={item.digit2_pool_limit} onChange={($event) => handleInputChange(item, index, $event.target)} /></div>
                  </div>
                  <div className="row p-1 mx-0">
                    <button className="btn btn-primary ml-auto"
                      onClick={() => saveRegionTimeLimit(index)}
                    >{Lang.save}</button>
                  </div>
                  <hr />
                  <div className="row p-1">
                    <div className="col-3 py-1">{Lang.special_number}</div>
                    <div className="col-1 py-1 text-center">{Lang.num}</div>
                    <div className="col-3">
                      <input type="text" className="form-control" minLength="2" maxLength="5" name="number" value={specialNumInputs[index] ? specialNumInputs[index].number : ''} onChange={($event) => handleSpecialNumInput(index, $event.target)} />
                    </div>
                    <div className="col-1 py-1 text-center">{Lang.limit}</div>
                    <div className="col-3">
                      <input type="text" className="form-control" name="limit" value={specialNumInputs[index] ? specialNumInputs[index].limit : ''} onChange={($event) => handleSpecialNumInput(index, $event.target)} />
                    </div>
                  </div>
                  <div className="row p-1 mx-0">
                    <button className="btn btn-primary ml-auto"
                      onClick={() => addSpecialNumber(index)}
                    >{Lang.add}</button>
                  </div>
                </div>

                <div className="col-4 section-right">
                  <div className="row mx-0 py-2">
                    <div className="col-4">{Lang.number}</div>
                    <div className="col-4">{Lang.limit}</div>
                    <div className="col-4"></div>
                  </div>
                  {item.special_numbers && item.special_numbers.map((special, iindex) =>
                    <div className="row mx-0 py-1" key={iindex}>
                      <div className="col-4 py-1">{special.number}</div>
                      <div className="col-4 py-1">{special.limit}</div>
                      <div className="col-4">
                        <button type="button" className="btn btn-danger"
                          onClick={() => deleteSpecialNumber(special)}
                        >{Lang.delete}</button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer">
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2" fillRule="evenodd"></path></svg>
          </button>
          <button className="btn btn-pagination">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true"><path d="M17.707 9.293l-5-5a.999.999 0 1 0-1.414 1.414L14.586 9H3a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414" fillRule="evenodd"></path></svg>
          </button>
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default NumberMoneyLimit;
